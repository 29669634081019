import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { withStyles } from '@material-ui/core/styles'
import Layout from '../components/layout'
import SEO from '../components/seo'
import LayoutInner from '../components/layoutInner'
import { Formik } from 'formik'
import { Button, Grid, TextField } from '@material-ui/core'
import { keys } from 'lodash'
import * as PATHS from '../utils/paths'
import { isNonEmpty, isValidEmailAddress, isValidMessageLength, isValidNameLength } from '../utils'
import * as amplitude from "@amplitude/analytics-browser";

const styles = theme => ({
    root: {},
    heroText: {
        fontSize: 32,
        lineHeight: "48px",
        fontWeight: 400,
        marginBottom: 16
    },
    subheroText: {
        fontSize: 18,
        lineHeight: "32px",
        fontWeight: "regular",
        marginBottom: 16
    },
});

const FORM_URL = "https://us-central1-three-is-a-pattern.cloudfunctions.net/contactFormSignup"

const _Page = ({data, location, classes}) => {
    const siteTitle = data.site.siteMetadata.title
    const [submitSuccess, setSubmitSuccess] = useState(false)

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title={"Contact 3iap"} location={{pathname: PATHS.CONTACT_PAGE}}/>

            <LayoutInner>

                <h1>Let's connect.</h1>

                <p className={classes.subheroText}>
                    How can 3iap help? Let's connect to find out. You can either 1) email <a href={"mailto:hi@3isapattern.com"}>hi@3isapattern.com</a>,
                    2) book a time on <a href={"https://calendly.com/3iap/intro-call"}>3iap's Calendly</a>, or
                    3) use the form below to send a message.
                </p>
            </LayoutInner>

            {!submitSuccess && (<LayoutInner>
                <br/><hr/><br/><br/>
                <h3>Quick contact form</h3>
                <p>Add your information below and we'll get back to you right away.</p>
                <Formik
                    initialValues={{
                        emailAddress: '',
                        firstName: '',
                        lastName: '',
                        organization: '',
                        contactReason: ''}}
                    validate={values => {
                        const errors = {};
                        const validators = {
                            emailAddress: [[isNonEmpty, 'Required'],
                                [isValidEmailAddress, 'Invalid email address'],
                                [isValidNameLength, 'This is too long']
                            ],
                            firstName: [
                                [isNonEmpty, 'Required'],
                                [isValidNameLength, 'This is too long']],
                            lastName: [[isValidNameLength, 'This is too long']],
                            organization: [[isValidNameLength, 'This is too long']],
                            contactReason: [[isValidMessageLength, 'This is too long']],
                        }

                        keys(validators).forEach(fieldName=>{
                            const fieldValidators = validators[fieldName]
                            // console.log(fieldName)
                            // console.log(fieldValidators)
                            for (let i= 0; i< fieldValidators.length; i++) {
                                // console.log(i)
                                const [isValidFn, errorMessage] = fieldValidators[i]
                                // console.log(isValidFn)
                                if (!isValidFn(values[fieldName])) {
                                    errors[fieldName] = errorMessage
                                    break
                                }
                            }
                        })

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {

                        fetch(FORM_URL, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(values)
                        }).then(response => {
                            console.log(response.body)
                            setSubmitting(false);
                            setSubmitSuccess(true);
                            amplitude.track('Contact Form Success');
                        })

                        // setTimeout(() => {
                        //     alert(JSON.stringify(values, null, 2));
                        //     setSubmitting(false);
                        // }, 400);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container>
                                <Grid item xs={12} sm={10}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField name="firstName" label="First name"
                                                       error={errors.firstName && touched.firstName}
                                                       helperText={errors.firstName}
                                                       variant="outlined"
                                                       fullWidth
                                                       required
                                                       onChange={handleChange}
                                                       onBlur={handleBlur}
                                                       value={values.firstName}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField name="lastName" label="Last name"
                                                       error={errors.lastName && touched.lastName}
                                                       helperText={errors.lastName}
                                                       variant="outlined"
                                                       fullWidth
                                                       onChange={handleChange}
                                                       onBlur={handleBlur}
                                                       value={values.lastName}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField name="emailAddress" label="Work email address"
                                                       variant="outlined"
                                                       error={errors.emailAddress && touched.emailAddress}
                                                       helperText={errors.emailAddress}
                                                       fullWidth
                                                       required
                                                       onChange={handleChange}
                                                       onBlur={handleBlur}
                                                       value={values.emailAddress}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField name="organization" label="Organization"
                                                       variant="outlined"
                                                       error={errors.organization && touched.organization}
                                                       helperText={errors.organization}
                                                       fullWidth
                                                       onChange={handleChange}
                                                       onBlur={handleBlur}
                                                       value={values.organization}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField name="contactReason" label="How can we help?"
                                                       variant="outlined"
                                                       error={errors.contactReason && touched.contactReason}
                                                       helperText={errors.contactReason}
                                                       fullWidth
                                                       multiline
                                                       rows={4}
                                                       onChange={handleChange}
                                                       onBlur={handleBlur}
                                                       value={values.contactReason}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant={"contained"} color="primary"
                                                    type="submit"
                                                    disabled={isSubmitting || 0 < keys(errors).length}>Submit</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>





                            {/*<span>{errors.emailAddress && touched.emailAddress && errors.emailAddress}</span>*/}



                        </form>
                    )}
                </Formik>
                <br/><br/>

            </LayoutInner>)}

            {submitSuccess && (
                <LayoutInner>
                    <br/><br/>
                    <h3>Thanks for reaching out. We'll follow up by email shortly.</h3>
                    <br/><br/>
                </LayoutInner>
            )}

        </Layout>
    )
}

export const Page = withStyles(styles)(_Page)
export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
